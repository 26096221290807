import getters from './getters';

jest.mock('@vivotek/lib-medama', () => ({
  PLUGINFREE: { SUPPORT_VIDEO_CODEC: [] },
}));

describe('computed', () => {
  describe('validScenes', () => {
    it('should give valid scenes array from store', () => {
      const state = {
        scenes: [
          {
            pages:
            [
              {
                layout: '2x2',
              }
            ],
            name: 'scene-1'
          }
        ],
      };
      expect(getters.validScenes(state)[0].name).toEqual('scene-1');
      expect(getters.validScenes(state)[0].index).toEqual(0);
    });
  });

  describe('activeScene', () => {
    it('should return active scene config from store active index', () => {
      const state = {
        isSceneMode: true,
        scenes: [
          {
            name: '1',
          },
          {
            name: '2',
          },
          {
            name: 'test scene 3',
          },
        ],
        active: 2,
      };
      expect(getters.activeScene(state).name).toEqual('test scene 3');
    });
    it('should return device scene config when isSceneMode is false', () => {
      const state = {
        isSceneMode: false,
        deviceScene: {
          name: 'device-list',
        },
      };
      expect(getters.activeScene(state).name).toEqual('device-list');
    });
  });

  describe('pages', () => {
    it('should return pages from active scene config', () => {
      const theGetters = {
        activeScene: {
          pages: [{
            layout: '5x5'
          }],
        },
      };
      expect(getters.pages({}, theGetters)).toEqual([{
        layout: '5x5'
      }]);
    });
  });
  describe('sortedScenes', () => {
    it('should return array with name asc order', () => {
      const state = {
        scenes: [
          { name: 'evil resident' },
          { name: 'silent hill' },
        ]
      };
      expect(getters.sortedScenes(state)('name', 'asc', '')).toEqual([{ index: 0, name: 'evil resident' }, { index: 1, name: 'silent hill' }]);
    });
    it('should return array with name desc order', () => {
      const state = {
        scenes: [
          { name: 'evil resident' },
          { name: 'silent hill' },
        ]
      };
      expect(getters.sortedScenes(state)('name', 'desc', '')).toEqual([{ index: 1, name: 'silent hill' }, { index: 0, name: 'evil resident' }]);
    });
    it('should return empty if scene is undefined or searchText is empty', () => {
      const emptyState = { scenes: [] };
      expect(getters.sortedScenes(emptyState)('name', 'asc', 'silent')).toEqual([]);
      const state = {
        scenes: [
          { name: 'evil resident' },
          { name: 'silent hill' },
        ]
      };
      expect(getters.sortedScenes(state)('name', 'asc', '')).toEqual([{ index: 0, name: 'evil resident' }, { index: 1, name: 'silent hill' }]);
    });
    it('should return array of object\'s name containing search text ', () => {
      const state = {
        scenes: [
          { name: 'evil resident' },
          { name: 'silent hill' },
        ]
      };
      expect(getters.sortedScenes(state)('name', 'asc', 'silent')).toEqual([{ index: 1, name: 'silent hill' }]);
    });
  });

  describe('hasNoViewcells', () => {
    it('should return true when all viewcells are empty in all pages', () => {
      const theGetters = {
        activeScene: {
          pages: [{
            view: [{ channel: -1, isEmpty: true }]
          }, {
            view: [{ channel: -1, isEmpty: true }]
          }],
        },
      };
      expect(getters.hasNoViewcells({}, theGetters)).toEqual(true);
    });
  });
});
